.hero {
    --underline-offset: 0.2em;
    text-align: center;
    z-index: 1;
    padding: 40px 0 0;
    color: #000;
    font-weight: 400;
    @media screen and (min-width: 768px) {
        padding: 50px 0 0;
    }
}
.preheader {
    font-family: var(--rubik);
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5;
    letter-spacing: 0.64px;
    text-transform: uppercase;
    color: #717171;
    margin: 1em 0;
}
.header {
    font-size: clamp(55px, 6vw, 64px);
    font-weight: 700;
    margin: 0;
    line-height: 1;
    letter-spacing: 0.8px;
    @media screen and (min-width: 1440px) {
        font-size: 64px;
    }
    u {
        font-size: clamp(45px, 5vw, 64px);
        color: var(--primary-brand-color-600);

        /* @media screen and (min-width: 1440px) {
            font-size: 64px;
        } */
    }
    [lang='vi'] & {
        font-weight: 600;
    }
}

.text {
    font-size: 16px;
    margin: 35px auto 27px;
    max-width: 800px;
    text-wrap: balance;
    line-height: 1.6;
    letter-spacing: 0.1px;
    @media screen and (min-width: 1440px) {
        font-size: 18px;
    }
}

.buttonWithText {
    a {
        font-size: 18px;
        font-weight: 600;
    }
    small {
        display: block;
        font-size: 10px;
        font-weight: 400;
        margin-top: 1.7em;
        span {
            color: #adaeb4;
        }
    }
}

.image {
    display: block;
    height: auto;
    max-width: 100%;
    margin: 28px auto 0;
    transform: translateX(-3px);
}

.animation {
    display: block;
    aspect-ratio: 16 / 9;
    transform: translate(16px, 16px);
    max-width: 955px;
    margin: 0 auto;
}
.picture {
    margin: 29px 0 0;
    img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
    }
}
